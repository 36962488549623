<div class="container">
  <div [ngClass]="{'privatePolicy': translate.is_arabic, 'privatePolicyEn': !translate.is_arabic}">
    <div class="row">
      <div class="col-3 d-lg-inline d-none">
        <div class="header-menu">
          <p>{{translate.is_arabic ? 'الأسئله الشائعه' : 'Common Qestions'}}</p>
        </div>
        <div class="body-menu">
          <ul>
            <li><p [class]="first" (click) = "onActive('first')">{{translate.is_arabic ? 'الأسئله العامه' : 'General Questions'}}</p></li>
            <li><p [class]="second" (click) = "onActive('second')">{{translate.is_arabic ? 'طرق الدفع' : 'Payment methods'}}</p></li>
            <li><p [class]="third" (click) = "onActive('third')">{{translate.is_arabic ? 'إلغاء الحجز' : 'Cancel Reservation'}}</p></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-9 col-12">

        <div class="body-content">
          <div class="header-content">
            <p class="d-lg-inline d-none">{{translate.is_arabic ? 'أسئله عامه' : 'General Question' }}</p>
            <p class="d-lg-none d-inline">{{translate.is_arabic ? 'الأسئله الشائعه' : 'Common Qestions'}}</p>
          </div>

          <div class="d-lg-none d-inline mobile-menu">
            <ul>
              <li><p [class]="first" (click)="onActive('first')">{{translate.is_arabic ? 'أسئله عامه' : 'General Question' }}</p></li>
              <li><p [class]="second" (click) = "onActive('second')">{{translate.is_arabic ? 'طرق الدفع' : 'Payment methods'}}</p></li>
            <li><p [class]="third" (click) = "onActive('third')">{{translate.is_arabic ? 'إلغاء الحجز' : 'Cancel Reservation'}}</p></li>
            </ul>
          </div>

          <p class="active mb-3 d-lg-none d-inline">{{generalQuestions ? generalQuestions[translate.data.requestdData.genType] : 'N/A'}}</p>

          <div *ngIf="first">
            <div *ngFor="let generalQuestion of generalQuestions">
              <p class="title">{{generalQuestion ? generalQuestion[translate.data.requestdData.genQuastion] : 'N/A'}}</p>
              <p class="answer">{{generalQuestion ?  generalQuestion[translate.data.requestdData.genAnswer] : 'N/A'}}</p>
            </div>
          </div>

          <div *ngIf="second">
            <div *ngFor="let paymentMethod of paymentMethods">
              <p class="title">{{paymentMethod ? paymentMethod[translate.data.requestdData.genQuastion] : 'N/A'}}</p>
              <p class="answer">{{paymentMethod ? paymentMethod[translate.data.requestdData.genAnswer] : 'N/A'}}</p>
            </div>
          </div>

          <div *ngIf="third">
            <div *ngFor="let removeItem of removeReverse">
              <p class="title">{{removeItem ? removeItem[translate.data.requestdData.genQuastion] : 'N/A'}}</p>
              <p class="answer">{{removeItem ? removeItem[translate.data.requestdData.genAnswer] : 'N/A'}}</p>
            </div>
          </div>

        <!--   <div class="Pagination">
            <img class="right" src="../../../assets/Pagination/Group 10357.svg" alt="">
            <img class="left" src="../../../assets/Pagination/Group 10358.svg" alt="">
          </div> -->

        </div>
      </div>
    </div>
  </div>
</div>
