import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService} from '../../../pages/pages.service';

@Component({
  selector: 'app-share-your-car-questions',
  templateUrl: './share-your-car-questions.component.html',
  styleUrls: ['./share-your-car-questions.component.scss']
})
export class ShareYourCarQuestionsComponent implements OnInit {

    //objects data to show
    generalQuestions: any = [];
    paymentMethods: any = [];
    removeReverse: any = [];

    first:string = "active"
    second:string = ""
    third:string =""

    id:any;

  constructor(
    public translate: TranslationService,
    private spinner: NgxSpinnerService,
    private pagesService: PagesService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.pagesService.getQuestionAnswer().subscribe(
       (response: any) => {
         console.log(response);
         response.data?.map((item:any)=>{
           console.log("item",item.questionType);
          switch(item.questionType){
            case 'الأسئله العامه' : this.generalQuestions.push(item); break;
            case 'طرق الدفع' : this.paymentMethods.push(item); break;
            case 'إلغاء الحجز' : this.removeReverse.push(item); break;
            default : break;
          }
         })
         console.log("this.generalQuestions",this.generalQuestions,this.paymentMethods,this.removeReverse);

        //  this.generalQuestions = response.data[0];
        //  this.paymentMethods = response.data[1];
        //  this.removeReverse = response.data[2];
         this.spinner.hide();
       },
       (error:any) => {
         console.log(error);
       }
     )
  }

  //active tab color
  onActive(type: string) {
    switch(type) {
      case 'first' :
        this.first = "active";
        this.second = "";
        this.third = "";
        break;
      case 'second' :
        this.first = "";
        this.second = "active";
        this.third = "";
        break;
      case 'third' :
        this.first = "";
        this.second = "";
        this.third = "active";
    }
  }

}
