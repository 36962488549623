import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/Services/authentication.service';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-share-your-car-video',
  templateUrl: './share-your-car-video.component.html',
  styleUrls: ['./share-your-car-video.component.scss']
})
export class ShareYourCarVideoComponent implements OnInit {

  constructor(private pagesService: PagesService,
    private router: Router,
    private route: ActivatedRoute,
    public translate : TranslationService,
    private authService: AuthenticationService,
     public sharedService: SharedService) { }

  singleVideoSrc: string;
  vediosSrc: string[];

  sharedSteps: any[];
  
  ngOnInit(): void {

    //get vedios data
    this.pagesService.getVedioSite().subscribe(
      (response: any) => {
        console.log('vediiiio ===> ', response);
        this.singleVideoSrc = response.data[0].path;
        this.vediosSrc = response.data.map((element: any) => {
          return element.path;
        });
        console.log(this.vediosSrc)
      },
      (error) => {
        console.log(error);
      }
    );

    //get shared steps
    this.pagesService.sharedSteps().subscribe(
      (response: any) => {
        console.log('shared steeeeps ==== ', response);
        this.sharedSteps = response.data;
      }
    )
  }

  handelChangeVedio(src: string) {
    this.singleVideoSrc = src;
    console.log(this.singleVideoSrc)
  }


  //handel share kararvan
  handelShareKaravan() {
    if(this.authService.isAuth()) {
      this.router.navigate(['../share'], {relativeTo: this.route});
    } else {
      this.sharedService.stringErrorMessage.next('يرجي تسجيل الدخول اولاً');
      this.sharedService.errorShowMessage.next(true);
      this.sharedService.hideErrorMessage();
    }
  }
}
