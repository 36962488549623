<div class="features">
  <p class="main-title">{{translate.is_arabic ? 'مميزات اضافية' : 'More Features'}}</p>
  <div class="row">
    <div class="col-12 col-md-3" *ngFor="let feature of additionalFeatures">
      <div class="content-feat">
        <p class="title">{{feature[translate.data.requestdData.genTite]}}</p>
        <p class="disc">{{feature[translate.data.requestdData.genDescription]}}</p>
      </div>
    </div>
  </div>
</div>
