import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';

@Component({
  selector: 'app-share-your-car',
  templateUrl: './share-your-car.component.html',
  styleUrls: ['./share-your-car.component.scss']
})
export class ShareYourCarComponent implements OnInit {

  constructor(public translate: TranslationService) { }

  ngOnInit(): void {
  }

}
