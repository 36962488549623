<div class="features">
  <div class="header">
    <p class="sub-title">{{translate.is_arabic ? 'اشترك معنا الان' : 'Subscribe with us now'}}</p>
    <h2 class="title">{{translate.is_arabic ? 'مميزات يقدما لك الموقع' : 'Advantages of the site'}}</h2>
  </div>
  <div class="body">
    <div class="row">
      <div class="col-12 col-md-3" *ngFor="let featureSite of siteFeatruesData">
        <div class="card-container">
          <img src="{{sharedService.ipRootImg + featureSite.photo}}" alt="">
          <p>{{featureSite[translate.data.requestdData.genTite]}}</p>
          <p style="color: #ccc; font-size: 12px;margin: 10px 0;">{{featureSite[translate.data.requestdData.genDescription]}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
