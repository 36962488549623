import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-share-your-car-features',
  templateUrl: './share-your-car-features.component.html',
  styleUrls: ['./share-your-car-features.component.scss']
})
export class ShareYourCarFeaturesComponent implements OnInit {

  constructor(private pagesService: PagesService,
    public translate: TranslationService,
     public sharedService: SharedService) { }

  siteFeatruesData: any[];

  ngOnInit(): void {
    this.pagesService.getSiteFeatures().subscribe(
      (response: any) => {
        console.log('git site features ====> ', response);
        this.siteFeatruesData = response.data;
        console.log('site featress data ===> ', this.siteFeatruesData);
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
