<div class="video">
  <div class="row">
    <div class="col-lg-6 col-12">
      <div class="video-container">
        <video width="100%" class="singleVedio" [src] = "sharedService.ipRootImg + singleVideoSrc" controls>
          Your browser does not support HTML5 video.
      </video>
      <div class="img-sm-container">
        <video class="vedioo" width="80px"
             *ngFor="let src of vediosSrc"
              src="{{sharedService.ipRootImg + src}}"
              (click)="handelChangeVedio(src)"
              [ngClass]="{'activeVedio': src == singleVideoSrc}">
        </video>

      </div>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="content">
        <h1>{{translate.is_arabic ? 'كيفيه مشاركه الكارافان الخاص بك' : 'How to share your camper'}}</h1>
        <ul class="list-unstyled">
          <li *ngFor="let step of sharedSteps; let i = index">
            <span>{{i + 1}}</span>{{step.detailAr}}
          </li>
        </ul>
        <div class="button-container">
          <button (click)="handelShareKaravan()">{{translate.is_arabic ? 'شارك الأن' : 'Share Now'}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
