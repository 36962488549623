import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShareYourCarRoutingModule } from './share-your-car-routing.module';
import { ShareYourCarComponent } from './share-your-car.component';
import { ShareYourCarFeaturesComponent } from './share-your-car-features/share-your-car-features.component';
import { ShareYourCarVideoComponent } from './share-your-car-video/share-your-car-video.component';
import { ShareYourCarMoreFeaturesComponent } from './share-your-car-more-features/share-your-car-more-features.component';
import { PagesSharedModule } from '../pagesShared.module';
import { ShareYourCarQuestionsComponent } from './share-your-car-questions/share-your-car-questions.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [ShareYourCarComponent,
      ShareYourCarFeaturesComponent,
      ShareYourCarVideoComponent,
      ShareYourCarMoreFeaturesComponent,
      ShareYourCarQuestionsComponent],
  imports: [
    CommonModule,
    ShareYourCarRoutingModule,
    PagesSharedModule,
    SharedModule,
  ]
})
export class ShareYourCarModule { }
