import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-share-your-car-more-features',
  templateUrl: './share-your-car-more-features.component.html',
  styleUrls: ['./share-your-car-more-features.component.scss']
})
export class ShareYourCarMoreFeaturesComponent implements OnInit {

  constructor(private pagesService: PagesService, public translate: TranslationService) { }

  additionalFeatures: any[];

  ngOnInit(): void {
    this.pagesService.getAdditionalFeature().subscribe(
      (response: any) => {
        console.log('getAdditionalFeature', response);
        this.additionalFeatures = response.data;
        console.log(this.additionalFeatures);
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
