<div [ngClass]="{'shareCar': translate.is_arabic, 'shareCarEn': !translate.is_arabic}">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="header">
          <p>{{translate.is_arabic ? 'كسب الدخل من شاحنتك' : 'Earn income from your truck'}}</p>
        </div>
        <div class="body">
          <app-share-your-car-features></app-share-your-car-features>
          <app-share-your-car-video></app-share-your-car-video>
          <app-share-your-car-more-features></app-share-your-car-more-features>
          <app-our-clients></app-our-clients>
          <app-share-your-car-questions></app-share-your-car-questions>
        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
